import React from "react";
import logo from "./images/logo.svg";
import linkedin from "./images/linkedin.png";
import twitter from "./images/twitter.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="app-content">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Building next-generation, high quality software and products.
          </p>{" "}
          <p>If you’re here, you’re early. 😊</p>
          <p>
            Contact us at:{" "}
            <a href="mailto:hello@hajimari.co">hello@hajimari.co</a>
          </p>
          <div className="social-wrapper">
            <a
              className="social-link"
              href="https://twitter.com/HajimariLabs"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} className="social-logo" alt="Twitter" />
            </a>
            <a
              className="social-link"
              href="https://www.linkedin.com/company/hajimari-labs"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} className="social-logo" alt="LinkedIn" />
            </a>
          </div>
        </div>
      </header>
      <div className="footer">&copy; Copyright 2022 Hajimari Labs Pte Ltd</div>
    </div>
  );
}

export default App;
